import React from 'react'
import { useStaticQuery, graphql } from "gatsby"

import Blurb from '../components/Blurb'
import Layout from '../components/layout'
import AccommodationsMap from '../components/AccommodationsMap'
import SEO from '../components/SEO';

import dmiIcon from '../images/dmi-logo.png'

const CENTER_LOCATION = {
  lat: 42.2456472,
  lng: -74.1315849,
};

const MARKERS = [{
  title: 'Deer Mountain Inn',
  latlng: {lat: 42.2205125, lng: -74.1197869},
  anchor: 'dmi',
  customMarker: dmiIcon,
  alwaysLabel: true,
}, {
  title: 'Albergo Allegria',
  latlng: {lat: 42.3058696, lng: -74.2303876},
  anchor: 'albergo',
}, {
  title: 'Eastwind Hotel',
  latlng: {lat: 42.3087789, lng:-74.2322903},
  anchor: 'eastwind',
}, {
  title: 'The Mountain Brook',
  latlng: {lat: 42.1978656, lng:-74.1378605},
  anchor: 'mountainbrook',
}, {
  title: 'Washington Irving Inn',
  latlng: {lat: 42.191212, lng:-74.1648902},
  anchor: 'washingtonirving',
}, {
  title: 'Hunter Inn',
  latlng: {lat: 42.20201, lng:-74.1996226},
  anchor: 'hunterinn',
},  {
  title: 'Kaatskill Mountain Club',
  latlng: {lat: 42.2045645, lng: -74.2160395},
  anchor: 'kaatskill',
}];

export const fixedImage = graphql`
  fragment fixedImage on File {
    childImageSharp {
      fixed(width: 153, height: 153, cropFocus: CENTER ) {
        ...GatsbyImageSharpFixed
      }
    }
  }
`;

const AccommodationsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      dmi: file(relativePath: { regex: "/dmi.jpg/" }) {...fixedImage}
      airbnb: file(relativePath: { regex: "/airbnb.jpg/" }) {...fixedImage}
      washingtonirving: file(relativePath: { regex: "/washingtonirving.jpg/" }) {...fixedImage}
      eastwind: file(relativePath: { regex: "/eastwind.jpg/" }) {...fixedImage}
      kaatskill: file(relativePath: { regex: "/kaatskill.jpg/" }) {...fixedImage}
      albergo: file(relativePath: { regex: "/albergo.jpg/" }) {...fixedImage}
      mountainbrook: file(relativePath: { regex: "/mountainbrook.jpg/" }) {...fixedImage}
      hunterinn: file(relativePath: { regex: "/hunterinn.jpg/" }) {...fixedImage}
      scribners: file(relativePath: { regex: "/scribners.jpg/" }) {...fixedImage}
    }
  `);

  return (
    <Layout>
      <SEO title="Accomodations"/>
      <Blurb isFirst={true}>
        If you’re coming from one of the official hotels, 
        here are the shuttle times for the wedding on Saturday:
        <ul>
          <li>3:45 Kaatskill Mountain Club</li>
          <li>3:50 Eastwind Hotel</li>
          <li>4:00 Albergo Allegria</li>
          <li>4:00 Washington Irving Inn</li>
          <li>4:15 Casa Strasser</li>
          <li>4:10 Hunter Inn</li>
          <li>4:20 The Mountain Brook</li>
        </ul>
        <footer />
      </Blurb>
      <Blurb>
        This is somewhat more complicated than we’d like, because a rival wedding across town got an early
        hold on tons of hotel rooms. We hope it rains on their ceremony. <strong><em>Please note: The hotels 
        report a lot of demand for rooms that weekend, so your best bet is to act fast!</em></strong>
      </Blurb>

      <Blurb anchor="dmi" title="Deer Mountain Inn" titleUrl="http://deermountaininn.com/" image={data.dmi.childImageSharp.fixed}>
        There are very few rooms at the Inn itself, and they’ll
        mostly be occupied by family. We’d like you to book elsewhere — but you’re
        always welcome to stop by and hang out.
      </Blurb>

      <AccommodationsMap center={CENTER_LOCATION} markers={MARKERS}/>

      <h2>Our Top Recommendations</h2>

      <Blurb title="AirBnb" titleUrl="https://www.airbnb.com/s/Tannersville--NY--United-States/homes?refinement_paths%5B%5D=%2Fhomes&query=Tannersville%2C%20NY%2C%20United%20States&checkin=2019-09-20&checkout=2019-09-22&adults=1&children=0&infants=0&guests=1&allow_override%5B%5D=&zoom=15&search_by_map=true&sw_lat=42.1887000072054&sw_lng=-74.15314890768383&ne_lat=42.20744217156&ne_lng=-74.11237933065746&map_toggle=true&s_tag=lnA9Sa2A"
        image={data.airbnb.childImageSharp.fixed}>
        We strongly recommend you look for Airbnb options, especially
        if you have other friends or family who will part of our celebration with whom you can
        share a house. This is prime weekend-place territory, and there are many fantastic
        options nearby.
      </Blurb>

      <Blurb anchor="mountainbrook" title="The Mountain Brook" titleUrl="http://www.hotelmountainbrook.com/" image={data.mountainbrook.childImageSharp.fixed}>
        UPDATED, AUGUST 9:
        This lovely Catskills lodge is located around the
        corner from Deer Mountain. It’s the perfect place to stay. The Other Wedding had blocked
        all of its rooms, but they’re now available. Rooms range from $200 to $375 per 
        night, plus a $650 three-bedroom suite, all with a two-night minimum. There are only a few
        rooms left, so call ASAP.
      </Blurb>

      <Blurb anchor="washingtonirving" title="Washington Irving Inn" titleUrl="https://washingtonirving.com/" image={data.washingtonirving.childImageSharp.fixed}>
        This bed and breakfast down on the main drag (less than a 10-minute drive away) gets wonderful reviews. 
        People seem to <em>love</em> the breakfasts. We have a block held, and rooms run from
        $175 to $210 per night, with a two night minimum. Call 518-589-5560 and tell them you’re with the 
        Oxfeld-Strasser wedding.
      </Blurb>

      <Blurb anchor="eastwind" title="Eastwind Hotel" titleUrl="https://www.eastwindny.com" image={data.eastwind.childImageSharp.fixed}>
        This new boutique hotel opened last summer in a renovated old bunkhouse. About 20 minutes away from Deer 
        Mountain, in the direction away from New York City, it’s cute, well-appointed, and gets 
        amazing reviews. We have rooms on hold through August 15, so act fast. There are a mix 
        of king rooms and double queen rooms, some of which can comfortably hold 
        more than two people. There are also a few glamping cabins that seem pretty cool, if that’s 
        your thing.
        Rates run from $299 to $379 per night, with a two-night minimum. Call 518-734-0553, and 
        tell them you’re with the Oxfeld-Strasser wedding.
      </Blurb>

      <Blurb anchor="kaatskill" title="Kaatskill Mountain Club" titleUrl="https://www.huntermtn.com/on-site-lodging/the-kaatskill-mountain-club/"
        image={data.kaatskill.childImageSharp.fixed}>
        The slopeside hotel at Hunter Mountain is just
        over 10 minutes away. It’s the equivalent of a Ramada by the highway: Perfectly fine,
        perfectly clean, nothing special. Because it’s marketed to families on ski weekends,
        there are several room types, from “studios” to multi-bedroom suites. Most include
        kitchens.
        <br/><br/>
        We have a block reserved here including studios ($200/night), one-bedroom suites ($250),
        and two-bedroom suites ($385). There are some other options available as well. A two-night
        minimum is required for the group price, but you can book a single night at prevailing rates.
        Call 518-263-5580 and tell them you’re with the Oxfeld-Strasser wedding.
      </Blurb>

      <Blurb anchor="albergo" title="Albergo Allegria" titleUrl="https://albergousa.com/" image={data.albergo.childImageSharp.fixed}>
        This bed and breakfast has a bunch of rooms and gets
        great reviews. It’s about 20 minutes away from Deer Mountain, across from the Eastwind Hotel.
        They don’t do wedding blocks, but prices range from $150 to $240 per night,
        with a two-night minimum.
      </Blurb>

      <h2>Other Options</h2>

      <Blurb anchor="hunterinn" title="Hunter Inn" titleUrl="http://hunterinn.com/" image={data.hunterinn.childImageSharp.fixed}>
        A dated but well-kept skiers’ roadside motel, this is a basic,
        fine option that’s located about 10 minutes away. It’s less cheap than you’d think it should
        be, at $180 to $270 per night. There’s no minimum stay.
      </Blurb>

      <Blurb anchor="scribers" title="Scribner’s Catskill Lodge" titleUrl="http://www.scribnerslodge.com/" image={data.scribners.childImageSharp.fixed}>
        This place is very nearby and way overrated. We
        stayed there once, and after Jesse very politely spent 45 minutes not complaining about
        how long breakfast was taking, it turned out the server had forgotten to place our order.
        It’s also the site of the Other Wedding, and so they say they have no rooms available. Plus,
        if we’re lucky it’ll rain there.
      </Blurb>

    </Layout>
  );
}
export default AccommodationsPage
