import React from 'react'
import GoogleMapReact from 'google-map-react';

import Marker from './Marker';


class AccommodationsMap extends React.Component {
  render() {
    return (
      <div style={{ height: '600px', width: '100%',  paddingTop: '1.5em' }}>
        <GoogleMapReact
          bootstrapURLKeys={{key: "AIzaSyA_ygYmhZug-eU8BiWmijiC_-ehCSsv3ls"}}
          defaultCenter={this.props.center}
          defaultZoom={12}
        >
          {this.props.markers.map(marker => <div {...marker.latlng} key={marker.anchor}><Marker {...marker} /></div>)}
        </GoogleMapReact>
      </div>
    );
  }
}

export default AccommodationsMap
