import React from 'react'
import classNames from 'classnames';

import css from './Marker.module.css';

class Marker extends React.Component {
  render() {
    return (
      <a href={`#${this.props.anchor}`} className={classNames(css.marker, {[css.alwaysLabel]: this.props.alwaysLabel})}>
        <div className={css.dot} />
        <div className={css.label}>
          <div className={css.text}>
            {this.props.customMarker && <img alt="map marker" src={this.props.customMarker} />}
            {this.props.title}
          </div>
        </div>
      </a>
    );
  }
}

export default Marker
